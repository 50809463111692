<template>
    <v-card :key="`${r.id}-${r.expiry}`" :class="{ 'mt-3': i > 0 }">
      <v-card-text class="pt-0">
        <div class="caption grey--text">Reservation Id: {{ r.id }}</div>
        <div class="text-h5">Event: {{ tournament }}</div>
        <div class="text-h5">Divison: {{ division }}</div>
        <div class="text-h6 text-center">
          Time to complete:
          <Countdown :expiry="r.expiry" :lengthInSeconds="0" :mini="true" @expired="$emit('expired')"></Countdown>
        </div>
      </v-card-text>
      <v-window v-model="window">
        <v-window-item :key="0">
          <v-card-actions class="justify-center">
            <v-btn v-if="inCart" color="success white--text" :to="`/checkout`" @click="$emit('complete-click')">Checkout</v-btn>
            <v-btn v-else color="success white--text" :to="`/tournament/${r.tournamentId}?tab=register`" @click="$emit('complete-click')">Go to event</v-btn>
            <v-btn color="success" text :loading="loading.includes(`keep-alive-${r.id}`)" @click.stop="keepAlive(r)">Keep alive</v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :key="1">
          <v-card-text class="text-center">
            Are you sure you want to cancel this registration?
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="success white--text" text @click="cancel">Yes</v-btn>
            <v-btn color="error" @click.stop="window = 0">no</v-btn>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </v-card>
</template>

<script>
const Countdown = () => import('@/components/Utils/Countdown')

export default {
  props: ['reservation', 'info', 'i', 'inCart'],
  data () {
    return {
      window: 0,
      loading: []
    }
  },
  computed: {
    r () {
      return this.reservation
    },
    tournament () {
      const i = this.info
      return i ? i.tournament : null
    },
    division () {
      const i = this.info
      if (i) {
        const wl = this.r.waitlist ? ' - Waitlist' : ''
        return `${i.division}${wl}`
      }
      return null
    },
    showCancelBtn () {
      return this.$route.fullPath !== `/tournament/${this.r.tournamentId}?tab=register`
    }
  },
  methods: {
    keepAlive (reservation) {
      this.loading.push(`keep-alive-${reservation.id}`)
      this.$store.dispatch('KEEP_ALIVE', reservation)
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = this.loading.filter(f => f !== `keep-alive-${reservation.id}`)
        })
    },
    cancel (reservation) {
      console.log(this.$route)
      // this.$store.dispatch('CANCEL_RESERVATION', this.reservation)
      this.$emit('complete-click')
    }
  },
  components: {
    Countdown
  }
}
</script>
